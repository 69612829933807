import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);
import { setCookie, getCookie, delCookie } from '@/utils/cookie';

export default new Vuex.Store({
  // state: {
  //   Authorization: localStorage.getItem('Authorization')
  //     ? localStorage.getItem('Authorization')
  //     : '',
  // },
  // mutations: {
  //   changeLogin(state, user) {
  //     state.Authorization = user.Authorization;
  //     localStorage.setItem('Authorization', user.Authorization);
  //   },
  // },
  state: {
    Authorization: getCookie('Authorization') ? getCookie('Authorization') : '',
  },
  mutations: {
    changeLogin(state, user) {
      state.Authorization = user.Authorization;
      setCookie('Authorization', user.Authorization, 7200);
    },
  },
  actions: {},
  modules: {},
});
