var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-cart"},[_c('div',{staticClass:"page-cart-item",on:{"click":_vm.home}},[_c('div',{staticClass:"page-cart-item-block"},[_vm._m(0),_c('div',{staticClass:"page-cart-item-block-title"},[_c('span',[_vm._v(_vm._s(_vm.$t('floatNav.Home')))])])])]),_c('div',{staticClass:"page-cart-item",on:{"click":_vm.cart}},[(_vm.isLogin(false))?_c('div',{staticClass:"num"},[_vm._v(_vm._s(_vm.num))]):_vm._e(),_c('div',{staticClass:"page-cart-item-block"},[_vm._m(1),_c('div',{staticClass:"page-cart-item-block-title"},[_c('span',[_vm._v(_vm._s(_vm.$t('floatNav.Cart')))])])])]),_c('div',{staticClass:"page-cart-item",on:{"click":_vm.user}},[_c('div',{staticClass:"page-cart-item-block"},[_vm._m(2),_c('div',{staticClass:"page-cart-item-block-title"},[_c('span',[_vm._v(_vm._s(_vm.$t('floatNav.Account')))])])])]),_c('div',{staticClass:"page-cart-item",on:{"click":_vm.changgou}},[_c('div',{staticClass:"page-cart-item-block"},[_vm._m(3),_c('div',{staticClass:"page-cart-item-block-title"},[_c('span',[_vm._v(_vm._s(_vm.$t('floatNav.Habits')))])])])]),_c('div',{staticClass:"page-cart-item",on:{"click":_vm.kefu}},[_c('div',{staticClass:"page-cart-item-block"},[_vm._m(4),_c('div',{staticClass:"page-cart-item-block-title"},[_c('span',[_vm._v(_vm._s(_vm.$t('floatNav.Live_Support')))])])])]),(_vm.isManager)?_c('div',{staticClass:"page-cart-item",on:{"click":_vm.cManager}},[_c('div',{staticClass:"page-cart-item-block"},[_vm._m(5),_c('div',{staticClass:"page-cart-item-block-title"},[_c('span',[_vm._v(_vm._s(_vm.$t('floatNav.Account_Manager')))])])])]):_vm._e(),_c('div',{staticClass:"page-cart-item"},[_c('div',{staticClass:"page-cart-item-block",on:{"click":_vm.toTop}},[_vm._m(6),_c('div',{staticClass:"page-cart-item-block-title"},[_c('span',[_vm._v(_vm._s(_vm.$t('floatNav.Back_Top')))])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-cart-item-block-img"},[_c('img',{attrs:{"src":require("../assets/cart/home.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-cart-item-block-img drMsg"},[_c('img',{attrs:{"src":require("../assets/cart/cart.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-cart-item-block-img"},[_c('img',{attrs:{"src":require("../assets/cart/user.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-cart-item-block-img"},[_c('img',{attrs:{"src":require("../assets/cart/changgou.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-cart-item-block-img"},[_c('img',{attrs:{"src":require("../assets/cart/customer-service.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-cart-item-block-img"},[_c('img',{attrs:{"src":require("../assets/cart/jl.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-cart-item-block-img"},[_c('img',{attrs:{"src":require("../assets/cart/back-top.png")}})])
}]

export { render, staticRenderFns }