<template>
  <div>
    <!-- 头部 -->
    <Header ref='getname'/>
    <!-- 搜索 -->
    <Search />
    <!-- 导航菜单 -->
    <Menu />
    <!-- 导航菜单背景 -->
    <div class="banner w-1920">
      <img class="back" src="../../assets/home/banner/1.png" alt="" />
      <div class="classification">
        <div class="w-1200 classification1">
          <div
            class="img"
            v-for="(item, index) in banner_img"
            :key="index"
            @click="more1(item.id)"
          >
            <div class="span">{{item.title}}</div>
            <img :src="item.img" alt="" />
          </div>
        </div>
      </div>
    </div>
    <!-- 新品首发，排行榜，行业资讯 -->
    <div class="list w-1920">
      <div class="conter w-1200">
        <div class="top">
          <!-- 新品首发 -->
          <Menu1 />
          <!-- 排行榜 -->
          <div class="menu menu2">
            <div class="top_T">
              <p class="title">排行榜</p>
            </div>
            <div class="qiehuan">
              <div
                class="daohang"
                :class="[
                  { bian: index == TabIndex1 },
                  { 'border-right-line': index != TabList1.length - 1 },
                ]"
                v-for="(item, index) in paihang"
                :key="index"
                @mouseover="selectedTabItem1(index)"
              >
                {{ item }}
              </div>
            </div>
            <div v-if="TabIndex1 == 0">
              <div
                class="menu2_list"
                v-if="moreList.productspus&&moreList.productspus[0]"
                @click="
                  detail(
                    moreList.productspus[0].storeId,
                    moreList.productspus[0].spuId
                  )
                "
              >
                <div class="no">
                  <Uimage src="../../assets/home/paihang/no1.png" alt="" />
                </div>
                <div class="pic">
                  <Uimage :src="moreList.productspus[0].url" alt="" />
                </div>
                <div class="name">
                  {{ moreList.productspus[0].name }}
                </div>
              </div>
              <div
                class="menu2_list"
                v-if="moreList.productspus&&moreList.productspus[1]"
                @click="
                  detail(
                    moreList.productspus[1].storeId,
                    moreList.productspus[1].spuId
                  )
                "
              >
                <div class="no">
                  <Uimage src="../../assets/home/paihang/no2.png" alt="" />
                </div>
                <div class="pic">
                  <Uimage :src="moreList.productspus[1].url" alt="" />
                </div>
                <div class="name">
                  {{ moreList.productspus[1].name }}
                </div>
              </div>
              <div
                class="menu2_list"
                v-if="moreList.productspus&&moreList.productspus[2]"
                @click="
                  detail(
                    moreList.productspus[2].storeId,
                    moreList.productspus[2].spuId
                  )
                "
              >
                <div class="no">
                  <Uimage src="../../assets/home/paihang/no3.png" alt="" />
                </div>
                <div class="pic">
                  <Uimage :src="moreList.productspus[2].url" alt="" />
                </div>
                <div class="name">
                  {{ moreList.productspus[2].name }}
                </div>
              </div>
            </div>
            <div v-if="TabIndex1 == 1">
              <div
                class="menu2_list"
                v-if="moreList1.productspus&&moreList1.productspus[0]"
                @click="
                  detail(
                    moreList1.productspus[0].storeId,
                    moreList1.productspus[0].spuId
                  )
                "
              >
                <div class="no">
                  <Uimage src="../../assets/home/paihang/no1.png" alt="" />
                </div>
                <div class="pic">
                  <Uimage :src="moreList1.productspus[0].url" alt="" />
                </div>
                <div class="name">
                  {{ moreList1.productspus[0].name }}
                </div>
              </div>
              <div
                class="menu2_list"
                v-if="moreList1.productspus&&moreList1.productspus[1]"
                @click="
                  detail(
                    moreList1.productspus[1].storeId,
                    moreList1.productspus[1].spuId
                  )
                "
              >
                <div class="no">
                  <Uimage src="../../assets/home/paihang/no2.png" alt="" />
                </div>
                <div class="pic">
                  <Uimage :src="moreList1.productspus[1].url" alt="" />
                </div>
                <div class="name">
                  {{ moreList1.productspus[1].name }}
                </div>
              </div>
              <div
                class="menu2_list"
                v-if="moreList1.productspus&&moreList1.productspus[2]"
                @click="
                  detail(
                    moreList1.productspus[2].storeId,
                    moreList1.productspus[2].spuId
                  )
                "
              >
                <div class="no">
                  <Uimage src="../../assets/home/paihang/no3.png" alt="" />
                </div>
                <div class="pic">
                  <Uimage :src="moreList1.productspus[2].url" alt="" />
                </div>
                <div class="name">
                  {{ moreList1.productspus[2].name }}
                </div>
              </div>
            </div>
            <div v-if="TabIndex1 == 2">
              <div
                class="menu2_list"
                v-if="moreList2.productspus&&moreList2.productspus[0]"
                @click="
                  detail(
                    moreList2.productspus[0].storeId,
                    moreList2.productspus[0].spuId
                  )
                "
              >
                <div class="no">
                  <Uimage src="../../assets/home/paihang/no1.png" alt="" />
                </div>
                <div class="pic">
                  <Uimage :src="moreList2.productspus[0].url" alt="" />
                </div>
                <div class="name">
                  {{ moreList2.productspus[0].name }}
                </div>
              </div>
              <div
                class="menu2_list"
                v-if="moreList2.productspus&&moreList2.productspus[1]"
                @click="
                  detail(
                    moreList2.productspus[1].storeId,
                    moreList2.productspus[1].spuId
                  )
                "
              >
                <div class="no">
                  <Uimage src="../../assets/home/paihang/no2.png" alt="" />
                </div>
                <div class="pic">
                  <Uimage :src="moreList2.productspus[1].url" alt="" />
                </div>
                <div class="name">
                  {{ moreList2.productspus[1].name }}
                </div>
              </div>
              <div
                class="menu2_list"
                v-if="moreList2.productspus&&moreList2.productspus[2]"
                @click="
                  detail(
                    moreList2.productspus[2].storeId,
                    moreList2.productspus[2].spuId
                  )
                "
              >
                <div class="no">
                  <Uimage src="../../assets/home/paihang/no3.png" alt="" />
                </div>
                <div class="pic">
                  <Uimage :src="moreList2.productspus[2].url" alt="" />
                </div>
                <div class="name">
                  {{ moreList2.productspus[2].name }}
                </div>
              </div>
            </div>
          </div>
          <!-- 行业资讯 -->
          <div class="menu menu4">
            <div class="top_T">
              <p class="title">行业资讯</p>
              <img
                @click="indestry"
                class="you"
                src="../../assets/home/youjiantou.png"
                alt=""
              />
            </div>
            <div class="nei" v-if="hangye != ''">
              <div class="nei_t">
                <div class="title">{{ hangye[0].title }}</div>
                <div class="img">
                  <img src="../../assets/home/hangye/logo.png" alt="" />
                </div>
              </div>
              <div class="nei_c">
                {{ hangye[0].jj }}
              </div>
              <div class="nei_b">
                <ul
                  v-for="(item, index) in hangye"
                  :key="index"
                  @click="detail_industry(item.id)"
                >
                  <li>
                    <div class="dian"></div>
                    <span class="span">{{ item.title }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="youdingwei">
        <Uimage src="../../assets/home/si/back.png" alt="" />
      </div>
    </div>

    <div class="more w-1920">
      <Uimage class="img1" src="../../assets/home/wu/back.jpg" alt="" />
      <Uimage class="img2" src="../../assets/home/wu/more.png" alt="" />
    </div>
    <!-- 标准气体楼层,标准气体 -->
    <div class="chanpin d_jump w-1920" v-if="moreList != ''">
      <div class="title w-1200">
        <div class="title_L">
          <Uimage src="../../assets/home/wu/shu.png" alt="" />
          <span class="spen1">{{ moreList.name }}</span>
          <!-- <span class="spen2">SIANDARD GAS</span> -->
        </div>
        <div class="gengduo" @click="more1(moreList.prodCatId)">
          <span>更多></span>
        </div>
      </div>
      <div class="qiti w-1200">
        <div class="left">
          <div class="left_title">{{ moreList.name }}</div>
          <div class="left_border"></div>
          <Uimage class="left_img" :src="moreList.img" alt="" />
        </div>
        <div class="conter">
          <!-- <div
            class="img1"
            v-for="(item, index) in moreList.productspus"
            :key="index"
            @click="detail(item.storeId, item.spuId)"
          >
            <Uimage :src="item.url" alt="" />
          </div> -->
          <el-table :data="moreList.productspus" style="width: 100%">
            <el-table-column prop="name" label="产品名称">
              <template slot-scope="scope">
                <el-link
                  :href="
                    `#/store/${scope.row.storeId}/details/${scope.row.spuId}`
                  "
                  target="_blank"
                >
                  <span v-html="scope.row.name"></span>
                </el-link>
              </template>
            </el-table-column>
            <el-table-column prop="bwNum" label="标物编号"></el-table-column>
            <el-table-column label="浓度范围" width="260px">
              <template slot-scope="scope">
                <div v-html="scope.row.concentrationRange"></div>
              </template>
            </el-table-column>
            <el-table-column label="所属店铺">
              <template slot-scope="scope">
                <el-link @click="StoreHome(scope.row.storeId)">{{
                  scope.row.storeName
                }}</el-link>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="right">
          <div class="qiehuan">
            <div
              class="moren"
              :class="[
                { gaibian: index1 == TabIndex2 },
                { 'border-right-line': index1 != TabList2.length - 1 },
              ]"
              v-for="(item1, index1) in liu"
              :key="index1"
              @mouseover="selectedTabItem2(index1, item1.id)"
            >
              {{ item1.title }}
            </div>
          </div>
          <!-- 新品排行 -->
          <div class="erji_menu" v-if="TabIndex2 == 0">
            <div
              class="list"
              v-for="(i, index) in moreList.productXPs"
              :key="index"
              @click="detail(i.storeId, i.spuId)"
            >
              <div class="paiming">{{ index + 1 }}</div>
              <div class="jieshao">
                <p>{{ i.name }}</p>
                <p>￥{{ i.price }}/瓶</p>
              </div>
              <div class="pic">
                <Uimage :src="i.url" alt="" />
              </div>
            </div>
          </div>
          <!-- 畅销排行 -->
          <div class="erji_menu" v-if="TabIndex2 == 1">
            <div
              class="list"
              v-for="(i, index) in moreList.productCXs"
              :key="index"
              @click="detail(i.storeId, i.spuId)"
            >
              <div class="paiming">{{ index + 1 }}</div>
              <div class="jieshao">
                <p>{{ i.name }}</p>
                <p>￥{{ i.price }}/瓶</p>
              </div>
              <div class="pic">
                <Uimage :src="i.url" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 标准气体楼层,食品类 -->
    <div class="chanpin d_jump w-1920" v-if="moreList1 != ''">
      <div class="title w-1200">
        <div class="title_L">
          <Uimage src="../../assets/home/wu/shu.png" alt="" />
          <span class="spen1">{{ moreList1.name }}</span>
          <!-- <span class="spen2">SIANDARD GAS</span> -->
        </div>
        <div class="gengduo" @click="more1(moreList1.prodCatId)">
          <span>更多></span>
        </div>
      </div>
      <div class="qiti w-1200">
        <div class="left">
          <div class="left_title">{{ moreList1.name }}</div>
          <div class="left_border"></div>
          <Uimage class="left_img" :src="moreList1.img" alt="" />
        </div>
        <div class="conter">
          <!-- <div
            class="img1"
            v-for="(item, index) in moreList1.productspus"
            :key="index"
            @click="detail(item.storeId, item.spuId)"
          >
            <Uimage :src="item.url" alt="" />
          </div> -->
           <el-table :data="moreList1.productspus" style="width: 100%">
            <el-table-column prop="name" label="产品名称">
              <template slot-scope="scope">
                <el-link
                  :href="
                    `#/store/${scope.row.storeId}/details/${scope.row.spuId}`
                  "
                  target="_blank"
                >
                  <span v-html="scope.row.name"></span>
                </el-link>
              </template>
            </el-table-column>
            <el-table-column prop="bwNum" label="标物编号"></el-table-column>
            <el-table-column label="含量规格" width="260px">
              <template slot-scope="scope">
                <div v-html="scope.row.concentrationRange"></div>
              </template>
            </el-table-column>
            <el-table-column label="所属店铺">
              <template slot-scope="scope">
                <el-link @click="StoreHome(scope.row.storeId)">{{
                  scope.row.storeName
                }}</el-link>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="right">
          <div class="qiehuan">
            <div
              class="moren"
              :class="[
                { gaibian: index1 == TabIndex3 },
                { 'border-right-line': index1 != TabList3.length - 1 },
              ]"
              v-for="(item1, index1) in liu"
              :key="index1"
              @mouseover="selectedTabItem3(index1, item1.id)"
            >
              {{ item1.title }}
            </div>
          </div>
          <!-- 新品排行 -->
          <div class="erji_menu" v-if="TabIndex3 == 0">
            <div
              class="list"
              v-for="(i, index) in moreList1.productXPs"
              :key="index"
              @click="detail(i.storeId, i.spuId)"
            >
              <div class="paiming">{{ index + 1 }}</div>
              <div class="jieshao">
                <p>{{ i.name }}</p>
                <p>￥{{ i.price }}/瓶</p>
              </div>
              <div class="pic">
                <Uimage :src="i.url" alt="" />
              </div>
            </div>
          </div>
          <!-- 畅销排行 -->
          <div class="erji_menu" v-if="TabIndex3 == 1">
            <div
              class="list"
              v-for="(i, index) in moreList1.productCXs"
              :key="index"
              @click="detail(i.storeId, i.spuId)"
            >
              <div class="paiming">{{ index + 1 }}</div>
              <div class="jieshao">
                <p>{{ i.name }}</p>
                <p>￥{{ i.price }}/瓶</p>
              </div>
              <div class="pic">
                <Uimage :src="i.url" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 标准气体楼层,环境类 -->
    <div class="chanpin d_jump w-1920" v-if="moreList2 != ''">
      <div class="title w-1200">
        <div class="title_L">
          <Uimage src="../../assets/home/wu/shu.png" alt="" />
          <span class="spen1">{{ moreList2.name }}</span>
          <!-- <span class="spen2">SIANDARD GAS</span> -->
        </div>
        <div class="gengduo" @click="more1(moreList2.prodCatId)">
          <span>更多></span>
        </div>
      </div>
      <div class="qiti w-1200">
        <div class="left">
          <div class="left_title">{{ moreList2.name }}</div>
          <div class="left_border"></div>
          <Uimage class="left_img" :src="moreList2.img" alt="" />
        </div>
        <div class="conter">
          <!-- <div
            class="img1"
            v-for="(item, index) in moreList2.productspus"
            :key="index"
            @click="detail(item.storeId, item.spuId)"
          >
            <Uimage :src="item.url" alt="" />
          </div> -->
           <el-table :data="moreList2.productspus" style="width: 100%">
            <el-table-column prop="name" label="产品名称">
              <template slot-scope="scope">
                <el-link
                  :href="
                    `#/store/${scope.row.storeId}/details/${scope.row.spuId}`
                  "
                  target="_blank"
                >
                  <span v-html="scope.row.name"></span>
                </el-link>
              </template>
            </el-table-column>
            <!-- <el-table-column prop="bwNum" label="产品编号"></el-table-column> -->
            <el-table-column label="规格型号" width="260px">
              <template slot-scope="scope">
                <div v-html="scope.row.concentrationRange"></div>
              </template>
            </el-table-column>
            <el-table-column label="所属店铺">
              <template slot-scope="scope">
                <el-link @click="StoreHome(scope.row.storeId)">{{
                  scope.row.storeName
                }}</el-link>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="right">
          <div class="qiehuan">
            <div
              class="moren"
              :class="[
                { gaibian: index1 == TabIndex4 },
                { 'border-right-line': index1 != TabList4.length - 1 },
              ]"
              v-for="(item1, index1) in liu"
              :key="index1"
              @mouseover="selectedTabItem4(index1, item1.id)"
            >
              {{ item1.title }}
            </div>
          </div>
          <!-- 新品排行 -->
          <div class="erji_menu" v-if="TabIndex4 == 0">
            <div
              class="list"
              v-for="(i, index) in moreList2.productXPs"
              :key="index"
              @click="detail(i.storeId, i.spuId)"
            >
              <div class="paiming">{{ index + 1 }}</div>
              <div class="jieshao">
                <p>{{ i.name }}</p>
                <p>￥{{ i.price }}/台</p>
              </div>
              <div class="pic">
                <Uimage :src="i.url" alt="" />
              </div>
            </div>
          </div>
          <!-- 畅销排行 -->
          <div class="erji_menu" v-if="TabIndex4 == 1">
            <div
              class="list"
              v-for="(i, index) in moreList2.productCXs"
              :key="index"
              @click="detail(i.storeId, i.spuId)"
            >
              <div class="paiming">{{ index + 1 }}</div>
              <div class="jieshao">
                <p>{{ i.name }}</p>
                <p>￥{{ i.price }}/台</p>
              </div>
              <div class="pic">
                <Uimage :src="i.url" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 底部腰线 -->
    <div class="zhiye d_jump w-1920" v-if="Category != ''">
      <div class="center w-1200">
        <div
          class="img img1"
          v-for="(item, index) in Category"
          :key="index"
          :style="{
            background: 'url( ' + item.footimg + ') no-repeat 100%,center',
          }"
          @click="more1(item.prodCatId)"
        >
          <div class="h1">{{ item.name }}</div>
          <div class="xian"></div>
          <p>{{ item.recommend }}</p>
          <div class="detail">查看详情</div>
        </div>
      </div>
      <div class="dingwei">
        <Uimage src="../../assets/home/qi/caidan.png" alt="" />
      </div>
    </div>

    <div style="width:100%;height:50px"></div>
    <!-- 平台推荐 -->
    <div class="swiper d_jump w-1920">
      <div class="title">
        <div class="top">
          <div style="letter-spacing:4px;">PLATFORM</div>
          <div style="letter-spacing:4px;">TO</div>
          <div style="letter-spacing:4px;">RECOMMEND</div>
        </div>
        <p style="letter-spacing:6px;">平台推荐</p>
      </div>
      <div class="w-1920">
        <div class="lunbo" v-if="tuijianpic != ''">
          <el-carousel
            :interval="4000"
            type="card"
            height="300px"
            :autoplay="true"
          >
            <el-carousel-item
              v-for="(item, index) in tuijianpic"
              :key="index"
              @click.native="detail(item.storeId, item.spuId)"
            >
              <Uimage :src="item.url" alt="" />
              <!-- <div class="name">{{ item.name }}</div> -->
            </el-carousel-item>
          </el-carousel>
          <!-- <swiper class="swiper" :options="swiperOption1" ref="mySwiper">

            <swiper-slide style="background: #007aff">
              I'm slide 1</swiper-slide
            >
            <swiper-slide style="background: yellow"> I'm slide 2</swiper-slide>
            <swiper-slide style="background: red"> I'm slide 3</swiper-slide>
            <swiper-slide style="background: green"> I'm slide 4</swiper-slide>

            <div class="swiper-pagination" slot="pagination"></div>
            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
          </swiper> -->
        </div>
        <!-- 企业logo轮播 -->
        <div class="zhong">
          <swiper :options="swiperOption">
            <swiper-slide v-for="(res, index) in companylogo" :key="index">
              <div class="company" @click="companylink(res.linkUrl)">
                <Uimage :src="res.linkLogo" alt="" />
                <div class="title">{{ res.linkTitle }}</div>
              </div>
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </div>
      </div>
    </div>

    <Foot />
    <Cart />
    <!-- <Nav /> -->
  </div>
</template>

<script>
//import HelloWorld from '@/components/HelloWorld.vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.min.css';
import Header from '@/components/header.vue';
import Cart from '@/components/cart.vue';
import Search from '../../components/home/search.vue';
import Menu from '@/components/home/menu.vue';
import Nav from '@/components/home/nav.vue';
import Foot from '@/components/foot.vue';
import slider from 'vue-image-scroll';
import { post, get } from '@/utils/request';
import Menu1 from './industry/menu.vue';
import Ranking from './industry/Ranking.vue';
import { setCookie, getCookie, delCookie } from '@/utils/cookie';
import { mapMutations } from 'vuex';
export default {
  name: 'Home',
  components: {
    Header,
    Search,
    Menu,
    Foot,
    slider,
    Cart,
    Nav,
    Swiper,
    SwiperSlide,
    Menu1,
  },
  data() {
    return {
      hangye: [],
      bannerList_T: [],
      getBrandTop_logo: [],
      moreList: [],
      moreList1: [],
      moreList2: [],
      Category: [],
      newgood: [],
      pai1: [],
      tuijianpic: [],
      //shangjia: [],
      swiperOption: {
        slidesPerView: 7,
        spaceBetween: 30,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      },
      swiperOption1: {
        effect: 'flip',
        grabCursor: true,
        pagination: {
          el: '.swiper-pagination',
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
      TabIndex1: 0,
      TabList1: [],
      list_paihang: [],
      liu: [
        {
          id: 1,
          title: '新品排行',
        },
        {
          id: 2,
          title: '畅销排行',
        },
      ],
      paihang: [],
      TabIndex2: 0,
      TabList2: [],
      TabIndex3: 0,
      TabList3: [],
      TabIndex4: 0,
      TabList4: [],
      banner_img: [
        {
          img: require('@/assets/home/banner/banner2.jpg'),
          id: 194,
          title:'标准气体',
        },
        {
          img: require('@/assets/home/banner/banner1.jpg'),
          id: 208,
          title:'标准液体',
        },
        {
          img: require('@/assets/home/banner/banner3.jpg'),
          id: 228,
          title:'仪器设备',
        },
      ],
      lunboBottom: [
        require('@/assets/home/ba/pic1.png'),
        require('@/assets/home/ba/pic2.png'),
        require('@/assets/home/ba/pic3.png'),
        require('@/assets/home/ba/pic1.png'),
      ],
      setting: {
        image: [
          require('@/assets/home/ba/pic1.png'),
          require('@/assets/home/ba/pic2.png'),
          require('@/assets/home/ba/pic3.png'),
          require('@/assets/home/ba/pic1.png'),
        ],
        autoRoll: false,
        styleObject: {
          width: '900',
          height: '250',
        },
      },
      companylogo: [],
    };
  },

  created() {
    //this.newgoods();
    this.getbanner_T();
    this.getmore();
    this.categoryBottom();
    this.paihang1();
    this.getfootSpulist();
    this.gethangye();
    this.getcompany();
  },
  mounted(){
    if(this.getQueryVariable('code')&&this.getQueryVariable('state')){
      this.getinfo()
    }
  },
  methods: {
    ...mapMutations(['changeLogin']),
    getinfo(){
      let _this = this;
      get('f/api/sms/getWxUserInfo?code='+this.getQueryVariable('code')+'&state='+this.getQueryVariable('state')+'').then((res)=>{
        if(res.data.code==0){
          _this.userToken = res.data.data.token;
          _this.changeLogin({ Authorization: _this.userToken });
          setCookie('username', res.data.data.username, 7200);
          setCookie('memberId', res.data.data.memberId, 7200);
          setCookie('companyName', res.data.data.companyName, 7200);
          setCookie('managerType', res.data.data.managerType, 7200);
          this.$refs.getname.getusername()
          this.$message.success({
            message: '登录成功',
          });
        }else{
          this.$message.error({
              message: res.data.msg,
            });
        }
      })
    },
    getQueryVariable(name){
      var search = window.location.search.substring(1)
      if (!search) {
          search = window.location.hash.split('?')[1]
      }
      if (search) {
          var obj = JSON.parse('{"' + decodeURIComponent(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
          return name ? obj[name] : obj
      }
    },
    StoreHome(e) {
      const { href } = this.$router.resolve({
        name: 'store_index',
        params: {
          storeId: e,
        },
      });
      window.open(href, '_blank');
    },
    companylink(e) {
      window.open(e, '_blank');
    },
    getcompany() {
      get('f/api/frontLink/getPlatformLinklist').then((res) => {
        this.companylogo = res.data.data;
      });
    },
    //行业资讯详情
    detail_industry(e) {
      this.$router.push({
        path: '/detail_industry',
        query: { id: e },
      });
    },
    //行业资讯
    indestry() {
      this.$router.push({
        name: 'Industry',
      });
    },
    clicklunbo(e) {},
    //楼层点击更多
    more1(name) {
      this.$router.push({
        path: '/search',
        query: {
          prodCatId: name,
        },
      });
    },
    //详情
    detail(storeId, spuId) {
      const { href } = this.$router.resolve({
        name: 'detail',
        params: {
          //店铺id
          storeId: storeId,
          //商品id
          spu: spuId,
        },
      });
      window.open(href, '_blank');
    },
    top() {
      this.$refs.img.prev();
    },
    bottom() {
      this.$refs.img.next();
    },
    //行业资讯
    gethangye() {
      get('f/api/information/list?pageNum=1&pageSize=7').then((res) => {
        if (res.data.data.list) {
          this.hangye = res.data.data.list;
        }
      });
    },
    //排行榜
    paihang1() {
      get('f/api/frontspu?orderSaleCount=1&page=1&size=6').then((res) => {
        if (res.data.data.list) {
          this.pai1 = res.data.data.list;
        }
      });
    },
    //热门商家
    // hotmerchant() {
    //   get('f/api/frontspu?orderCommentCoun=1&page=1&size=2').then((res) => {
    //     this.shangjia = res.data.data.list;
    //   });
    // },
    //新品首发
    // newgoods() {
    //   get('f/api/frontspu?labelCode=XP&page=1&size=4').then((res) => {
    //     this.newgood = res.data.data.list;
    //   });
    // },
    //底部腰线
    categoryBottom() {
      get('f/api/frontcategory/selProductCategoryBottom').then((res) => {
        if (res.data.data) {
          this.Category = res.data.data;
        }
      });
    },
    //楼层第五部分商品分类
    getmore() {
      get('f/api/frontcategory/getproductfloor').then((res) => {
        if (res.data.data) {
          if(res.data.data[0].productspus){
            res.data.data[0].productspus.forEach((item)=>{
              if(item.concentrationRange != null){
                item.concentrationRange=item.concentrationRange.replace(
                  'X10^(-6)',
                  'X10<sup>-6</sup>'
                );
              }
              
            });
            
            
          }
          if(res.data.data[1].productspus){
            res.data.data[1].productspus.forEach((item)=>{
              if(item.concentrationRange != null){
                item.concentrationRange=item.concentrationRange.replace(
                  'X10^(-6)',
                  'X10<sup>-6</sup>'
                );
              }
            });
          }
          if(res.data.data[2].productspus){
            res.data.data[2].productspus.forEach((item)=>{
              if(item.concentrationRange != null){
                item.concentrationRange=item.concentrationRange.replace(
                  'X10^(-6)',
                  'X10<sup>-6</sup>'
                );
              }
            });
          }
           
          this.moreList = res.data.data[0];
          if((this.moreList.productXPs==null||this.moreList.productXPs.length==0)&&(this.moreList.productCXs!=null&&this.moreList.productCXs.length>0)){
            this.TabIndex2 = 1
          }
          this.moreList1 = res.data.data[1];
          if((this.moreList1.productXPs==null||this.moreList1.productXPs.length==0)&&(this.moreList1.productCXs!=null&&this.moreList1.productCXs.length>0)){
            this.TabIndex3 = 1
          }
          this.moreList2 = res.data.data[2];
          if((this.moreList2.productXPs==null||this.moreList2.productXPs.length==0)&&(this.moreList2.productCXs!=null&&this.moreList2.productCXs.length>0)){
            this.TabIndex4 = 1
          }
          res.data.data.forEach((req) => {
            let index = 100;
            if (req.name.indexOf('标准物质') > -1) {
              index = req.name.indexOf('标准物质');
            }
            this.paihang.push(req.name.slice(0, index));
          });
        }
      });
    },

    //上面轮播
    getbanner_T() {
      get('f/api/frontcategory/gethomeimglist').then((res) => {
        //this.bannerList_T = res.data.data;
      });
    },
    //平台推荐
    getfootSpulist() {
      get('f/api/frontspu/getfootSpulist').then((res) => {
        if (res.data.data) {
          this.tuijianpic = res.data.data;
        }
      });
    },

    //查询商品品牌
    getBrandTop() {
      get('f/api/frontcategory/getBrandTop?pageNum=1&pageSize=10').then(
        (res) => {
          if (res.data.data) {
            this.getBrandTop_logo = res.data.data;
          }
        }
      );
    },
    store_index() {
      this.$router.push({
        name: 'store_index',
        params: {
          storeId: '1',
        },
      });
    },
    selectedTabItem(index, e) {
      this.TabIndex = index;
      this.caidan = true;
    },
    selectedTabItem1(index, e) {
      this.TabIndex1 = index;
    },
    selectedTabItem2(index, e) {
      this.TabIndex2 = index;
    },
    selectedTabItem3(index, e) {
      this.TabIndex3 = index;
    },
    selectedTabItem4(index, e) {
      this.TabIndex4 = index;
    },
  },
};
</script>
<style lang="scss" scoped>
.company {
  cursor: pointer;
  /*width: 188px;*/
  img {
    width: 90px;
    height: 40px;
  }
  .title {
    width: 90px;
    color: #333;
    font-size: 14px;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
.el-carousel__indicators li button {
  background-color: red;
}
.el-carousel__item .name {
  text-align: right;
  color: #000;
  font-size: 20px;
  position: absolute;
  z-index: 10;
  right: 50px;
  top: 20px;
  width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.el-carousel__item .decript {
  color: #333;
  position: absolute;
  z-index: 10;
  top: 55px;
  right: 50px;
  text-align: right;
  width: 350px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.el-carousel__item img {
  width: 100%;
  height: 100%;
  position: relative;
}

.banner {
  // height: 596px;
  height: 904px;
  position: relative;
  .back {
    width: 100%;
    height: 100%;
  }
  .classification {
    position: absolute;
    top: 563px;
    width: 100%;
    display: flex;
    justify-content: center;
    .classification1 {
      width: 1200px;
      display: flex;
      justify-content: space-around;
      .img {
        cursor: pointer;
        position: relative;
        img {
          box-shadow: 0 0 20px #333;
          height: 110px ;
          width: 380px;
          border-radius: 5px;
        }
        .span{
          position: absolute;
          width: 240px;
          height: 60px;
          left: 50%;
          transform: translate(-50%, -50%);
          top: 50%;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          border: .1px white solid;
          background: #858b8f45;
          color: #87817f;
          font-weight: 700;
          font-size: 24px;
        }
      }
    }
  }
}

//第四部分
.list {
  width: 100%;
  height: 477px;
  position: relative;
  z-index: 9;
  .conter {
    position: relative;
    width: 1234px;
    height: 477px;
    margin: 0 auto;

    .top {
      width: 1000px;
      position: absolute;
      left: 100px;
      top: -140px;
      z-index: 10;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      .menu {
        width: 319px;
        .qiehuan {
          display: flex;
          justify-content: center;
        }
        .daohang {
          color: #343434;
          border-radius: 25px;
          padding: 3px 10px;
          font-size: 14px;
          background: #fff;
          cursor: pointer;
          margin-left: 20px;
        }
        .bian {
          color: #fff5f3;
          background: #fe5a51;
          font-size: 14px;
          cursor: pointer;
        }
      }

      .menu2 {
        background: url('../../assets/home/paihang/back.png') no-repeat center
          center;
        height: 441px;
        width: 301px;
        padding: 0 20px;
        .top_T {
          display: flex;
          align-items: center;

          .title {
            font-size: 27px;
            color: #fff;
            // margin-left: 20px;
          }
        }
        .menu2_list {
          margin-top: 20px;
          height: 77px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-around;
          .no {
            width: 35px;
            height: 48px;
          }
          .pic {
            width: 77px;
            height: 77px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .name {
            cursor: pointer;
            width: 160px;
            font-size: 14px;
            color: #fff;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
          }
        }
      }

      .menu4 {
        width: 284px;
        height: 410px;
        .you {
          width: 100%;
          height: 100%;
          cursor: pointer;
        }
        background: url('../../assets/home/hangye/back.png') no-repeat center
          center;

        .top_T {
          display: flex;
          align-items: center;

          .title {
            font-size: 24px;
            color: #323335;
            margin-left: 20px;
          }

          img {
            width: 19px;
            height: 19px;
            margin-left: 10px;
          }
        }

        .nei {
          padding: 0 26px 0 24px;

          .nei_t {
            height: 50px;
            overflow: hidden;
            display: flex;
            justify-content: space-between;

            .title {
              width: 155px;
              height: 36px;
              font-size: 13px;
              color: #737170;
            }

            .img {
              width: 55px;
              height: 43px;

              img {
                width: 100%;
                height: 100%;
              }
            }
          }

          .nei_c {
            width: 100%;
            height: 60px;
            overflow: hidden;
            margin-top: 10px;
            font-size: 14px;
            color: #7f7c7b;
          }

          .nei_b {
            margin-top: 10px;

            ul {
              margin: 0;
              padding: 0;
              font-size: 14px;
              color: #7f7c7b;
              li {
                width: 100%;
                height: 16px;
                line-height: 17px;
                overflow: hidden;
                list-style-type: none;
                margin-top: 8px;
                color: #686868;
                display: flex;
                align-items: center;
                cursor: pointer;
                .dian {
                  width: 5px;
                  height: 5px;
                  background: #ff902a;
                }
                .span {
                  margin-left: 7px;
                  width: 100%;
                  height: 16px;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }
              }
            }
          }
        }
      }
    }

    .bottom {
      height: 110px;
      position: absolute;
      z-index: 10;
      bottom: 30px;
      box-shadow: 0 0 20px #e9f2f9;
      padding: 17px 35px 13px 34px;
      display: flex;
      justify-content: space-between;

      .zhong {
        overflow: hidden;
        width: 100%;
        height: 100%;
      }
    }
  }
  .youdingwei {
    position: absolute;
    right: 0;
    top: -55px;
    z-index: 2;
    img {
      height: 530px;
      width: 400px;
    }
  }
}

//第五部分
.more {
  width: 100%;
  height: 240px;
  position: relative;

  .img1 {
    width: 100%;
    height: 100%;
  }

  .img2 {
    width: 85px;
    height: 85px;
    position: absolute;
    left: 47.6%;
    top: 200px;
  }
}

//第六部分，产品
.chanpin {
  width: 100%;
  margin-top: 40px;

  .title {
    height: 60px;
    margin: 0 auto;
    line-height: 60px;
    border-bottom: 3px solid #1a52ff;
    color: #404040;
    display: flex;
    justify-content: space-between;

    .title_L {
      display: flex;
      align-items: center;

      .spen1 {
        font-size: 24px;
        margin-left: 10px;
      }

      .spen {
        font-size: 10px;
      }
    }

    .gengduo {
      color: #404040;
      cursor: pointer;
    }
  }

  .qiti {
    margin: 9px auto;
    width: 1234px;
    height: 600px;
    display: flex;
    justify-content: space-between;

    .left {
      width: 228px;
      height: 100%;
      position: relative;
      &_title{
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0%);
        top: 80px;
        text-align: center;
        color: #484644;
        font-weight: bold;
        font-size: 24px;
        height: 40px;
      }
      &_border{
        border-bottom: 4px solid #484644;
          width: 48px;
          position: absolute;
          left: 50%;
          top: 120px;
          transform: translate(-50%, 0%);
      }
      &_img {
        width: 100%;
        height: 100%;
      }
    }

    .conter {
      width: 785px;
      height: 100%;
      overflow: hidden;
      .img1 {
        width: 380px;
        height: 239px;
        position: relative;

        img {
          width: 100%;
          height: 100%;
        }
        .titname {
          position: absolute;
          right: 20px;
          top: 15px;
          font-size: 16px;
          color: #555;
        }
        .decript {
          position: absolute;
          right: 20px;
          top: 45px;
          font-size: 14px;
          color: #777;
          padding-left: 20px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 250px;
          text-align: right;
        }
        .detail {
          position: absolute;
          right: 20px;
          top: 80px;
          font-size: 14px;
          color: #fb0d00;
          width: 80px;
          height: 25px;
          line-height: 25px;
          border: 1px solid #fd7f71;
          text-align: center;
          cursor: pointer;
        }
      }
    }

    .right {
      width: 220px;
      height: 99.5%;
      border: 1px solid #eaeaea;
      border-left: none;
      overflow: hidden;
      .qiehuan {
        height: 28px;
        margin: 12px auto;
        line-height: 28px;
        font-size: 12px;
        display: flex;
        justify-content: center;
        .moren {
          width: 55px;
          color: #6a6e6f;
          border-radius: 25px;
          padding: 0 15px;
          font-size: 12px;
        }
        .gaibian {
          color: #fff;
          background: #1951fe;
          width: 55px;
          font-size: 12px;
          text-align: center;
        }
      }
      .erji_menu {
        .list {
          color: #606060;
          font-size: 12px;
          margin-top: 5px;
          height: 80px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .paiming {
            margin-left: 7px;
            width: 20px;
            height: 20px;
            background: #ff5a51;
            font-size: 10px;
            line-height: 20px;
            text-align: center;
            color: #fff;
            border-radius: 5px;
          }

          .jieshao {
            margin-left: 10px;
            cursor: pointer;
            p {
              width: 100px;
              margin: 0;
              padding: 0;
              margin-top: 5px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              overflow: hidden;
              -webkit-box-orient: vertical;
            }
          }

          .pic {
            width: 60px;
            height: 70px;
            margin-right: 5px;
            cursor: pointer;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
}

//第七部分
.zhiye {
  width: 100%;
  height: 534px;
  background: url('../../assets/home/qi/back.jpg') no-repeat center center;
  position: relative;
  .center {
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    .img {
      width: 233px;
      height: 382px;
      .h1 {
        font-size: 24px;
        margin: auto;
        margin-top: 50px;
        color: #333333;
        white-space: nowrap;
        text-align: center;
      }
      .xian {
        width: 150px;
        border-bottom: 4px solid #323234;
        margin: auto;
      }

      p {
        width: 150px;
        margin: 0 auto;
        text-align: center;
        margin-top: 20px;
        font-size: 15px;
        color: #333335;
      }

      .detail {
        width: 80px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        background: #1a51ff;
        color: #fff;
        font-size: 12px;
        margin: 15px auto;
      }
    }

    .img1 {
      background: url('../../assets/home/qi/pic1.png') no-repeat center center;
    }

    .img1:hover {
      opacity: 0.5;
      filter: alpha(opacity=50);
      -moz-opacity: 0.5;
      -khtml-opacity: 0.5;
      cursor: pointer;
      .h1 {
        font-size: 24px;
        margin: auto;
        margin-top: 50px;
        color: #ff4842;
      }
      .xian {
        width: 150px;
        border-bottom: 4px solid #ff4842;
        margin: auto;
      }

      p {
        width: 150px;
        margin: 0 auto;
        text-align: center;
        margin-top: 20px;
        font-size: 15px;
        color: #ff4842;
      }

      .detail {
        width: 80px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        background: #ff4842;
        color: #fff;
        font-size: 12px;
        margin: 15px auto;
      }
    }

    // .img2 {
    //   background: url('../../assets/home/qi/pic2.png') no-repeat center center;
    // }

    // .img2:hover {
    //   background: url('../../assets/home/qi/img2.png') no-repeat center center;
    // }

    // .img3 {
    //   background: url('../../assets/home/qi/pic3.png') no-repeat center center;
    // }

    // .img3:hover {
    //   background: url('../../assets/home/qi/img3.png') no-repeat center center;
    // }

    // .img4 {
    //   background: url('../../assets/home/qi/pic4.png') no-repeat center center;
    // }

    // .img4:hover {
    //   background: url('../../assets/home/qi/img.png') no-repeat center center;
    // }

    // .img5 {
    //   background: url('../../assets/home/qi/pic5.png') no-repeat center center;
    // }

    // .img5:hover {
    //   background: url('../../assets/home/qi/img5.png') no-repeat center center;
    // }
  }

  .dingwei {
    width: 94px;
    height: 94px;
    border-radius: 50%;
    position: absolute;
    left: 48%;
    top: 490px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

//轮播图
.swiper {
  width: 100%;
  height: 700px;
  margin-top: 47px;
  background: url('../../assets/home/ba/back.jpg') no-repeat center center;
  .title {
    width: 100%;

    .top {
      margin-top: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      div {
        margin-left: 20px;
        font-size: 14px;
        color: #181818;
      }
    }
    p {
      text-align: center;
      height: 20px;
      color: #181818;
      font-size: 18px;
    }
  }

  .lunbo {
    /*height: 450px;*/
    width: 900px;
    margin: 90px auto;

    .swiper1 {
      img {
        width: 463px;
        height: 100%;
      }
    }
  }
  .zhong{
    text-align: center;
    margin-left: 200px;
    width: calc(100% - 400px);
  }
}

</style>
<style lang="scss">
.swiper-wrapper {
    justify-content: center;
}
</style>