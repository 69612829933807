<template>
  <div class="page-cart">
    <div class="page-cart-item" @click="home">
      <div class="page-cart-item-block">
        <div class="page-cart-item-block-img">
          <img src="../assets/cart/home.png" />
        </div>
        <div class="page-cart-item-block-title">
          <span>{{ $t('floatNav.Home') }}</span>
        </div>
      </div>
    </div>
    <div class="page-cart-item" @click="cart">
      <div class="num" v-if="isLogin(false)">{{ num }}</div>
      <div class="page-cart-item-block">
        <div class="page-cart-item-block-img drMsg">
          <img src="../assets/cart/cart.png" />
        </div>
        <div class="page-cart-item-block-title">
          <span>{{ $t('floatNav.Cart') }}</span>
        </div>
      </div>
    </div>

    <div class="page-cart-item" @click="user">
      <div class="page-cart-item-block">
        <div class="page-cart-item-block-img">
          <img src="../assets/cart/user.png" />
        </div>
        <div class="page-cart-item-block-title">
          <span>{{ $t('floatNav.Account') }}</span>
        </div>
      </div>
    </div>

    <div class="page-cart-item" @click="changgou">
      <div class="page-cart-item-block">
        <div class="page-cart-item-block-img">
          <img src="../assets/cart/changgou.png" />
        </div>
        <div class="page-cart-item-block-title">
          <span>{{ $t('floatNav.Habits') }}</span>
        </div>
      </div>
    </div>

    <!-- <div class="page-cart-item">
      <div class="page-cart-item-block">
        <div class="page-cart-item-block-img">
          <img src="../assets/cart/after-sales.png" />
        </div>
        <div class="page-cart-item-block-title">
          <span>{{ $t('floatNav.After_Sales_Service') }}</span>
        </div>
      </div>
    </div> -->

    <div class="page-cart-item" @click="kefu">
      <div class="page-cart-item-block">
        <div class="page-cart-item-block-img">
          <img src="../assets/cart/customer-service.png" />
        </div>
        <div class="page-cart-item-block-title">
          <span>{{ $t('floatNav.Live_Support') }}</span>
        </div>
      </div>
    </div>

    <div class="page-cart-item" @click="cManager" v-if="isManager">
      <div class="page-cart-item-block">
        <div class="page-cart-item-block-img">
          <img src="../assets/cart/jl.png" />
        </div>
        <div class="page-cart-item-block-title">
          <span>{{ $t('floatNav.Account_Manager') }}</span>
        </div>
      </div>
    </div>

    <div class="page-cart-item">
      <div class="page-cart-item-block" @click="toTop">
        <div class="page-cart-item-block-img">
          <img src="../assets/cart/back-top.png" />
        </div>
        <div class="page-cart-item-block-title">
          <span>{{ $t('floatNav.Back_Top') }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { post, get } from '@/utils/request'

import { isLogin } from '@/utils/public'
import { getCookie } from '@/utils/cookie'

export default {
  data() {
    return {
      scrollTop: 0,
      time: 0,
      dParams: 20,
      scrollState: 0,
      num: 0,
      isManager: false
    }
  },
  computed: {
    showTop: function () {
      let value = this.scrollTop > 200 ? true : false
      return value
    }
  },
  mounted() {
    window.addEventListener('scroll', this.getScrollTop)
  },
  created() {
    const managerType = getCookie('managerType')
    if (managerType == '') {
      this.isManager = false
    } else if (managerType == 'null') {
      this.isManager = false
    } else if (managerType == '0') {
      this.isManager = false
    } else {
      this.isManager = true
    }
    this.$nextTick(() => {
      this.getcart()
    })
  },
  methods: {
    kefu() {
      var hm = document.createElement('script')
      hm.src = 'https://hm.baidu.com/hm.js?e75f348c042b56db44e38b8364ea6e3a'
      var s = document.getElementsByTagName('script')[0]
      s.parentNode.insertBefore(hm, s)
      // window.open(
      //   'https://cschat-ccs.aliyun.com/index.htm?tntInstId=o532_d88&scene=SCE00010277',
      //   '_blank'
      // );
    },
    isLogin,
    getcart() {
      if (isLogin(false)) {
        // get('api/cart/selCount').then((res) => {
        //   this.num = (res.data && res.data.data.count) || 0;
        // });
        get('api/cart/page').then((res) => {
          res.data.data.forEach((i) => {
            for (let q = 0; q < i.cartJoinList.length; q++) {
              for (let j = q + 1; j < i.cartJoinList.length; j++) {
                if (i.cartJoinList[q].remark == '1' && i.cartJoinList[j].remark == '1') {
                  if (
                    i.cartJoinList[q].name == i.cartJoinList[j].name &&
                    i.cartJoinList[q].storeId == i.cartJoinList[j].storeId &&
                    i.cartJoinList[q].prodCatId == i.cartJoinList[j].prodCatId &&
                    i.cartJoinList[q].batchNumber == i.cartJoinList[j].batchNumber
                  ) {
                    i.cartJoinList.splice(j, 1)
                  }
                }
              }
            }
          })
          res.data.data.forEach((i) => {
            for (let q = 0; q < i.cartJoinList.length; q++) {
              for (let j = q + 1; j < i.cartJoinList.length; j++) {
                if (i.cartJoinList[q].remark == '1' && i.cartJoinList[j].remark == '1') {
                  if (
                    i.cartJoinList[q].name == i.cartJoinList[j].name &&
                    i.cartJoinList[q].storeId == i.cartJoinList[j].storeId &&
                    i.cartJoinList[q].prodCatId == i.cartJoinList[j].prodCatId &&
                    i.cartJoinList[q].batchNumber == i.cartJoinList[j].batchNumber
                  ) {
                    i.cartJoinList.splice(j, 1)
                  }
                }
              }
            }
          })
          res.data.data.forEach((i) => {
            for (let q = 0; q < i.cartJoinList.length; q++) {
              for (let j = q + 1; j < i.cartJoinList.length; j++) {
                if (i.cartJoinList[q].remark == '1' && i.cartJoinList[j].remark == '1') {
                  if (
                    i.cartJoinList[q].name == i.cartJoinList[j].name &&
                    i.cartJoinList[q].storeId == i.cartJoinList[j].storeId &&
                    i.cartJoinList[q].prodCatId == i.cartJoinList[j].prodCatId &&
                    i.cartJoinList[q].batchNumber == i.cartJoinList[j].batchNumber
                  ) {
                    i.cartJoinList.splice(j, 1)
                  }
                }
              }
            }
          })
          this.num = res.data.data.length
          this.num = 0
          res.data.data.forEach((req) => {
            this.num = this.num + req.cartJoinList.length
          })
        })
      }
    },
    changgou() {
      this.$router.push({
        name: 'Changgou'
      })
    },
    home() {
      this.$router.push({
        name: 'Home'
      })
    },
    cart() {
      this.$router.push({
        name: 'Cart'
      })
    },
    user() {
      this.$router.push({
        name: 'user-centered'
      })
    },
    cManager() {
      this.$router.push({
        name: 'HuiYuan'
      })
    },
    toTop(e) {
      if (!!this.scrollState) {
        return
      }
      this.scrollState = 1
      e.preventDefault()
      let distance = document.documentElement.scrollTop || document.body.scrollTop
      let _this = this
      this.time = setInterval(function () {
        _this.gotoTop(_this.scrollTop - _this.dParams)
      }, 10)
    },
    gotoTop(distance) {
      this.dParams += 20
      distance = distance > 0 ? distance : 0
      document.documentElement.scrollTop = document.body.scrollTop = window.pageYOffset = distance
      if (this.scrollTop < 10) {
        clearInterval(this.time)
        this.dParams = 20
        this.scrollState = 0
      }
    },
    getScrollTop() {
      this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
    }
  }
}
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
  color: #676767;
}
div {
  cursor: pointer;
}
.page-cart {
  position: fixed;
  width: 82px;
  right: 0px;
  top: 20%;
  background: #fff;
  color: #676767;
  font-size: 12px;
  z-index: 999;
  display: grid;
  justify-content: space-around;
  grid-template-columns: repeat(auto-fill, 80px);
  grid-gap: 2px 0px;
  // border: 1px dashed #999;
  box-shadow: #676767 0px 0px 10px;
  &-item {
    .num {
      padding: 0 2px;
      min-width: 20px;
      height: 15px;
      border-radius: 25px;
      background: red;
      position: absolute;
      right: 10px;
      top: 5px;
      color: #fff;
      text-align: center;
      line-height: 15px;
    }
    width: 100%;
    height: 80px;
    position: relative;
    &:last-of-type {
      &:after {
        background: none;
      }
    }

    &:after {
      content: '';
      position: absolute;
      width: 80%;
      height: 2px;
      left: 0px;
      right: 0px;
      bottom: -2px;
      margin: auto;
      background: transparent;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-image: url('../assets/cart/image-02.png');
    }

    &-block {
      position: absolute;
      height: 50px;
      width: 100%;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      margin: auto;

      &-img {
        width: 25px;
        height: 25px;
        margin: auto;

        &.drMsg {
          position: relative;

          > .msg {
            position: absolute;
            right: -20px; /*no*/
            top: -5px; /*no*/
            fill: #fff;
            font-size: 10px; /*no*/
          }
        }

        > img {
          width: 100%;
          height: 100%;
        }
      }

      &-title {
        width: 100%;
        text-align: center;
      }
    }
  }
}
</style>
<style>
#aff-im-root .embed-icon-pcIcon5 {
  background-size: 100%;
}
</style>
