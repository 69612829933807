<template>
  <div class="content">
    <div class="foot w-1920">
      <div class="top">
        <div class="ul1" v-for="(item, index) in list" :key="index">
          <p>{{ item.articleTypeName }}</p>
          <ul>
            <li v-for="(item2, index2) in item.children" :key="index2" @click="handleMenuClick(item2.id)">{{ item2.articleTypeName }}</li>
          </ul>
        </div>

        <div class="ul1">
          <p class="p1">{{ $t('foot.Service_Hotline') }}</p>
          <p class="p2">0531-84771678</p>
          <div class="kefu" @click="kefu()">
            <img src="../assets/home/foot/kefu.png" alt="" />
            <span>{{ $t('foot.Live_Support') }}</span>
          </div>
        </div>
        <div class="ul1">
          <div class="erweima">
            <img src="../assets/home/foot/erweima.jpg" alt="" />
          </div>
        </div>
      </div>
      <div class="bot">
        <p class="p1">
          德洋（山东）化学有限公司 | 版权所有 | 并保留所有权利 |
          <a style="color: rgb(176, 207, 254); text-decoration: none" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=37012602000129" target="_blank">鲁公网安备37012602000129号</a>
          |
          <a style="color: rgb(176, 207, 254); text-decoration: none" href="https://beian.miit.gov.cn" target="_blank">备案号:鲁ICP备19059784号-2号</a>
          | 联系电话:0531-84771678
        </p>
        <p class="p2">公司地址:山东省济南市天桥区济南新材料交易中心办公楼307-1号 | 电子邮箱:biaowu@deyang.cc</p>
      </div>
    </div>
  </div>
</template>

<script>
import { post, get } from '@/utils/request'
export default {
  name: 'Foot',
  props: {
    msg: String
  },
  data() {
    return {
      list: []
    }
  },
  created() {
    this.$nextTick(() => {
      get('f/api/articleType/shopArticleTypeVo').then((res) => {
        this.list = res.data ? res.data.data : []
      })
    })
  },
  methods: {
    handleMenuClick(id) {
      this.$router.tp({
        name: 'helpCenter',
        params: {
          id: id
        }
      })
    },
    kefu() {
      // window.open('https://cschat-ccs.aliyun.com/index.htm?tntInstId=o532_d88&scene=SCE00010277', '_blank')
      var hm = document.createElement('script')
      hm.src = 'https://hm.baidu.com/hm.js?e75f348c042b56db44e38b8364ea6e3a'
      var s = document.getElementsByTagName('script')[0]
      s.parentNode.insertBefore(hm, s)
    }
  }
}
</script>

<style lang="scss" scoped>
li {
  cursor: pointer;
}

.foot {
  width: 100%;

  .top {
    height: 150px;
    background: #fff;
    margin: 0 auto;
    padding-top: 40px;
    display: flex;
    justify-content: center;

    .ul1 {
      height: 120px;
      font-size: 12px;
      color: #8d8d8d;
      border-right: 1px solid #d9d9d9;
      padding: 0 45px;

      p {
        font-size: 16px;
        color: #858585;
        margin: 0;
        padding: 0;
      }

      ul {
        padding: 0;
        margin: 0;
        margin-top: 10px;

        li {
          list-style-type: none;
          margin-top: 5px;
        }
      }

      .p1 {
        color: #404040;
      }

      .p2 {
        color: #404040;
      }

      .kefu {
        width: 120px;
        height: 31px;
        border-radius: 5px;
        background: #404042;
        color: #fff;
        font-size: 14px;
        text-align: center;
        margin-top: 24px;
        display: flex;
        align-items: center;

        img {
          width: 17px;
          height: 17px;
          margin-left: 10px;
          margin-right: 10px;
        }
      }

      .erweima {
        width: 111px;
        height: 111px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .bot {
    padding-top: 30px;
    background: #2179ff;
    height: 80px;
    color: #b0cffe;
    font-size: 12px;
    text-align: center;

    p {
      padding: 0;
      margin: 0;
    }

    .p2 {
      margin-top: 10px;
    }
  }
}
</style>
