import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/home/Home.vue';
import Order from '../views/order/order.vue';
import NProgress from 'nprogress'; // progress bar
import 'nprogress/nprogress.css'; // progress bar style
import { setCookie, getCookie, delCookie } from '@/utils/cookie';

Vue.use(VueRouter);

NProgress.configure({ showSpinner: false }); // NProgress Configuration

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/login/login.vue'),
  },
  {
    path: '/login_phone',
    name: 'Login_phone',
    component: () => import('../views/login/login_phone.vue'),
  },
  {
    path: '/password',
    name: 'Password',
    component: () => import('../views/login/password.vue'),
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/login/register.vue'),
  },
  {
    path: '/register_succed',
    name: 'Register_succed',
    component: () => import('../views/login/success.vue'),
  },
  {
    path: '/person_detail',
    name: 'Person_detail',
    component: () => import('../views/login/person_detail.vue'),
  },
  {
    path: '/order',
    name: 'Order',
    //redirect: '/dingdan',
    component: () => import('../views/order/order.vue'),

    children: [
      {
        path: 'dingdan',
        name: 'Dingdan',
        component: () => import('../views/order/dingdan/dingdan.vue'),
        meta: {
          isLogin: true,
        },
      },
      {
        path: 'fukuan',
        name: 'Fukuan',
        component: () => import('../views/order/dingdan/fukuan.vue'),
        meta: {
          isLogin: true,
        },
      },
      {
        path: 'fahuo',
        name: 'Fahuo',
        component: () => import('../views/order/dingdan/fahuo.vue'),
        meta: {
          isLogin: true,
        },
      },
      {
        path: 'huishou',
        name: 'Huishou',
        component: () => import('../views/order/dingdan/huishou.vue'),
        meta: {
          isLogin: true,
        },
      },
      {
        path: 'ping',
        name: 'Ping',
        component: () => import('../views/order/dingdan/pingjia.vue'),
        meta: {
          isLogin: true,
        },
      },
      {
        path: 'shouhuo',
        name: 'Shouhuo',
        component: () => import('../views/order/dingdan/shouhuo.vue'),
        meta: {
          isLogin: true,
        },
      },
      {
        path: 'shaidan',
        name: 'Shaidan',
        component: () => import('../views/order/dingdan/shaidan.vue'),
        meta: {
          isLogin: true,
        },
      },
      {
        path: 'yiping',
        name: 'Yiping',
        component: () => import('../views/order/dingdan/yipingjia.vue'),
        meta: {
          isLogin: true,
        },
      },
      {
        path: 'tuihuo',
        name: 'Tuihuo',
        component: () => import('../views/order/kehufuwu/kehufuwu/tuihuo.vue'),
        meta: {
          isLogin: true,
        },
      },
      // {
      //   path: 'tui',
      //   name: 'Tui',
      //   component: () => import('../views/order/kehufuwu/kehufuwu/tuihuo.vue'),
      //   meta: {
      //     isLogin: true,
      //   },
      // },
      {
        path: 'jilu',
        name: 'Jilu',
        component: () => import('../views/order/kehufuwu/kehufuwu/jilu.vue'),
        meta: {
          isLogin: true,
        },
      },
      {
        path: 'mingxi',
        name: 'Mingxi',
        component: () => import('../views/order/kehufuwu/kehufuwu/mingxi.vue'),
        meta: {
          isLogin: true,
        },
      },
      {
        path: 'fapiao',
        name: 'Fapiao',
        component: () => import('../views/order/kehufuwu/fapiao.vue'),
        meta: {
          isLogin: true,
        },
      },
      {
        path: 'jingli',
        name: 'Jingli',
        component: () => import('../views/order/kehufuwu/jingli.vue'),
        meta: {
          isLogin: true,
        },
      },
      {
        path: 'fapiao_detail',
        name: 'Fapiao_detail',
        component: () => import('../views/order/kehufuwu/fapiao_detail.vue'),
        meta: {
          isLogin: true,
        },
      },
      {
        path: 'address',
        name: 'Address',
        component: () => import('../views/order/zhanghu/address.vue'),
        meta: {
          isLogin: true,
        },
      },
      {
        path: 'bind',
        name: 'Bind',
        component: () => import('../views/order/zhanghu/bind.vue'),
        meta: {
          isLogin: true,
        },
      },
      {
        path: 'safe',
        name: 'Safe',
        component: () => import('../views/order/zhanghu/safe.vue'),
        meta: {
          isLogin: true,
        },
      },
      {
        path: 'user',
        name: 'User',
        component: () => import('../views/order/zhanghu/user.vue'),
        meta: {
          isLogin: true,
        },
      },
      {
        path: 'changgou',
        name: 'Changgou',
        component: () => import('../views/order/dingdan/changgou.vue'),
        meta: {
          isLogin: true,
        },
      },
      {
        path: 'yue',
        name: 'Yue',
        component: () => import('../views/order/zichan/yue.vue'),
        meta: {
          isLogin: true,
        },
      },
      {
        path: 'youhui',
        name: 'Youhui',
        component: () => import('../views/order/zichan/youhui.vue'),
        meta: {
          isLogin: true,
        },
      },
      {
        path: 'shopcard',
        name: 'Shopcard',
        component: () => import('../views/order/zichan/shopcard.vue'),
        meta: {
          isLogin: true,
        },
      },
      {
        path: 'jifen',
        name: 'Jifen',
        component: () => import('../views/order/zichan/jifen.vue'),
        meta: {
          isLogin: true,
        },
      },
      {
        path: '/shouxin',
        name: 'Shouxin',
        component: () => import('../views/order/zichan/shouxin.vue'),
        meta: {
          isLogin: true,
        },
      },
      {
        path: '/follow/store',
        name: 'store',
        component: () => import('../views/order/guanzhu/follow-store.vue'),
        meta: {
          isLogin: true,
        },
      },
      {
        path: '/follow/wares',
        name: 'wares',
        component: () => import('../views/order/guanzhu/follow-wares.vue'),
        meta: {
          isLogin: true,
        },
      },
      {
        path: '/wares/route',
        name: 'route',
        component: () => import('../views/order/guanzhu/wares-route.vue'),
        meta: {
          isLogin: true,
        },
      },
      {
        path: '/user-centered',
        name: 'user-centered',
        component: () => import('../views/user/index-centered.vue'),
        meta: {
          isLogin: true,
        },
      },
      {
        path: '/tuihuo/details',
        name: 'tuihuo-details',
        component: () => import('../views/order/kehufuwu/tuihuo-details.vue'),
        meta: {
          isLogin: true,
        },
      },
      {
        path: '/cManager/huiyuan',
        name: 'HuiYuan',
        component: () => import('../views/order/kehufuwu/huiyuan.vue'),
        meta: {
          isLogin: true,
        },
      },
      {
        path: '/cManager/dingdan',
        name: 'MdingDan',
        component: () => import('../views/order/kehufuwu/dingdan.vue'),
        meta: {
          isLogin: true,
        },
      },

      {
        path: '/cManager/shenhe',
        name: 'ShenHe',
        component: () => import('../views/order/kehufuwu/shenhe.vue'),
        meta: {
          isLogin: true,
        },
      },
    ],
  },
  {
    path: '/pingjia/:skuId/:orderId/:batchNumber',
    name: 'Pingjia',
    component: () => import('../views/order/pingjia.vue'),
    meta: {
      isLogin: true,
    },
  },
  {
    path: '/tuihuosq/:skuId/:orderId',
    name: 'Tuihuosq',
    component: () => import('../views/order/kehufuwu/tuihuosq.vue'),
    meta: {
      isLogin: true,
    },
  },
  {
    path: '/detail_dingdan',
    name: 'Detail_dingdan',
    component: () => import('../views/order/detail_dingdan.vue'),
    meta: {
      isLogin: true,
    },
  },
  {
    path: '/dingdan_detail',
    name: 'Dingdan_detail',
    component: () => import('../views/order/kehufuwu/dingdan_d.vue'),
    meta: {
      isLogin: true,
    },
  },
  {
    path: '/detail_pingjia',
    name: 'Detail_pingjia',
    component: () => import('../views/order/detail_pingjia.vue'),
    meta: {
      isLogin: true,
    },
  },
  {
    path: '/certPDF/:certUrl',
    name: 'CertPDF',
    component: () => import('../views/order/certPDF.vue'),
    meta: {
      isLogin: true,
    },
  },
  {
    path: '/chongzhi',
    name: 'Chongzhi',
    component: () => import('../views/order/zichan/chongzhi.vue'),
    meta: {
      isLogin: true,
    },
  },
  {
    path: '/selsect',
    name: 'Select',
    component: () => import('../views/order/zhanghu/selectphonenum.vue'),
    meta: {
      isLogin: true,
    },
  },
  {
    path: '/newselsect',
    name: 'Newselect',
    component: () => import('../views/order/zhanghu/newselectphonenum.vue'),
    meta: {
      isLogin: true,
    },
  },
  {
    path: '/changepassword',
    name: 'Changepassword',
    component: () => import('../views/order/zhanghu/changepassword.vue'),
    meta: {
      isLogin: true,
    },
  },
  {
    path: '/updatecompanyname',
    name: 'updatecompanyname',
    component: () => import('../views/order/zhanghu/updatecompanyname.vue'),
    meta: {
      isLogin: true,
    },
  },
  {
    path: '/paypass',
    name: 'Paypass',
    component: () => import('../views/order/zhanghu/paypass.vue'),
    meta: {
      isLogin: true,
    },
  },
  {
    path: '/mail',
    name: 'Mail',
    component: () => import('../views/order/zhanghu/mail.vue'),
    meta: {
      isLogin: true,
    },
  },
  {
    path: '/autonym',
    name: 'Autonym',
    component: () => import('../views/order/zhanghu/autonym.vue'),
    meta: {
      isLogin: true,
    },
  },
  // {
  //   path: '/way',
  //   name: 'Way',
  //   component: () => import('../views/order/zhanghu/way.vue'),
  //   meta: {
  //     isLogin: true,
  //   },
  // },
  // {
  //   path: '/verify',
  //   name: 'Verify',
  //   component: () => import('../views/order/zhanghu/verify.vue'),
  //   meta: {
  //     isLogin: true,
  //   },
  // },
  // {
  //   path: '/secced',
  //   name: 'Secced',
  //   component: () => import('../views/order/zhanghu/secced.vue'),
  //   meta: {
  //     isLogin: true,
  //   },
  // },
  {
    path: '/buy_succeed',
    name: 'Buy_succeed',
    component: () => import('../views/buy/buy_succeed.vue'),
    meta: {
      isLogin: true,
    },
  },
  {
    ///:orderSource/:receiverId/:mergeOrderNo/:sumPrice/:memberId/:managerId/:orderId/:sign/:couponId/:cardId/:useScore/:jifen/:exchangeScore/:skuId/:spuId/:num
    path: '/zhuanzhang/:price/',
    name: 'Zhuanzhang',
    component: () => import('../views/buy/zhuanzhang.vue'),
    meta: {
      isLogin: true,
    },
  },
  {
    ///:orderSource/:receiverId/:mergeOrderNo/:sumPrice/:memberId/:managerId/:orderId/:sign/:couponId/:cardId/:useScore/:jifen/:exchangeScore/:skuId/:spuId/:num
    path: '/pay-weixin/:price/',
    name: 'Pay-weixin',
    component: () => import('../views/buy/pay-weixin.vue'),
    meta: {
      isLogin: true,
    },
  },
  {
    ///:orderSource/:receiverId/:mergeOrderNo/:sumPrice/:memberId/:managerId/:orderId/:sign/:couponId/:cardId/:useScore/:jifen/:exchangeScore/:skuId/:spuId/:num
    path: '/pay-transfer/:price/',
    name: 'Pay-transfer',
    component: () => import('../views/buy/pay-transfer.vue'),
    meta: {
      isLogin: true,
    },
  },
  {
    ///:orderSource/:receiverId/:mergeOrderNo/:sumPrice/:memberId/:managerId/:orderId/:sign/:couponId/:cardId/:useScore/:jifen/:exchangeScore/:skuId/:spuId/:num
    path: '/pay_balance/:price/',
    name: 'Pay-balance',
    component: () => import('../views/buy/pay_balance.vue'),
    meta: {
      isLogin: true,
    },
  },
  {
    path: '/cart',
    name: 'Cart',
    component: () => import('../views/cart/cart.vue'),
    meta: {
      isLogin: true,
    },
  },
  {
    path: '/store/:storeId/classify/:selectMenu',
    name: 'store_classify',
    component: () => import('../views/store/index-classify.vue'),
    meta: {
      isLogin: true,
    },
  },
  {
    path: '/store/:storeId/search',
    name: 'store_search',
    component: () => import('../views/store/index-search.vue'),
    meta: {
      isLogin: true,
    },
  },
  {
    path: '/store/:storeId/index',
    name: 'store_index',
    component: () => import('../views/store/index.vue'),
    meta: {
      isLogin: true,
    },
  },
  {
    path: '/store/:storeId/details/:spu',
    name: 'detail',
    component: () => import('../views/details/index.vue'),
    meta: {
      isLogin: true,
    },
    // props: true
  },
  {
    path: '/settlement',
    name: 'settlement',
    component: () => import('../views/settlement/index.vue'),
    meta: {
      isLogin: true,
    },
    // props: true
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('../views/search/index.vue'),
    // meta: {
    //   isLogin: true,
    // },
  },
  {
    path: '/search/details',
    name: 'searchDetails',
    component: () => import('../views/search/index-details.vue'),
    // meta: {
    //   isLogin: true,
    // },
  },
  {
    path: '/search_store',
    name: 'Search_store',
    component: () => import('../views/search_store/search_store.vue'),
    // meta: {
    //   isLogin: true,
    // },
  },
  {
    path: '/store/ruzhu',
    name: 'ruzhu',
    component: () => import('../views/ruzhu/index.vue'),
    meta: {
      isLogin: true,
    },
  },
  {
    path: '/helpCenter/:id',
    name: 'helpCenter',
    component: () => import('../views/helpCenter/index.vue'),
    meta: {},
  },
  {
    path: '/helpCenter',
    name: 'helpCenter1',
    component: () => import('../views/helpCenter/index.vue'),
    meta: {},
  },
  {
    path: '/industry',
    name: 'Industry',
    component: () => import('../views/home/industry/industry.vue'),
  },
  {
    path: '/detail_industry',
    name: 'Detail_industry',
    component: () => import('../views/home/industry/detail_industry.vue'),
  },
  {
    path: '/integral',
    name: 'Integral',
    component: () => import('../views/home/jifen/integral.vue'),
  },
];

/* 原地TP企划 --- 开始 */
VueRouter.prototype.tp = function(config) {
  //由于vue的路由不能同一个位置反复调用
  //再次包装其逻辑为 push 另一个 vue 文件
  //再又 另一个 vue 文件进行 replace 回
  // a vue 页面
  // b vue 页面
  // c vue 页面 中转路径
  //调用路径 a->b->c->b
  //实际路径 a->b->b
  router.push({
    name: 'replace',
    params: {
      routeName: config.name,
      ...(config.params || {}),
    },
    query: {
      ...(config.query || {}),
    },
  });
};
/* 原地TP企划 --- 结束 */

const router = new VueRouter({
  scrollBehavior: () => ({ y: 0 }),
  // mode: 'history',
  routes: [
    ...routes,
    {
      path: '/replace/:routeName',
      name: 'replace',
      component: () => import('@/components/replace/index'),
    },
  ],
});

router.beforeEach((to, from, next) => {
  NProgress.start();
  if (to.meta.isLogin) {
    if (getCookie('Authorization')) {
      next();
    } else {
      next({
        name: 'Login',
      });
    }
  } else {
    next();
  }
});

router.afterEach(() => {
  NProgress.done(); // finish progress bar
});

export default router;
