<template>
  <div class="page-cart">
    <div
      class="page-cart-item"
      v-for="(item, index) in step"
      :key="index"
      @click="jump(index)"
    >
      <div class="page-cart-item-block">
        <div class="page-cart-item-block-img drMsg">
          <img :src="item.pic" />
        </div>
        <div
          class="page-cart-item-block-title"
          :class="{ active: item.isActive }"
        >
          <span>{{ item.title }}</span>
        </div>
      </div>
    </div>

    <!-- <div class="page-cart-item">
      <div class="page-cart-item-block">
        <div class="page-cart-item-block-img">
          <img src="../../assets/home/nav/shi1.png" />
        </div>
        <div class="page-cart-item-block-title">
          <span>食品标物</span>
        </div>
      </div>
    </div>

    <div class="page-cart-item">
      <div class="page-cart-item-block">
        <div class="page-cart-item-block-img">
          <img src="../../assets/home/nav/huan1.png" />
        </div>
        <div class="page-cart-item-block-title">
          <span>环境标物</span>
        </div>
      </div>
    </div>

    <div class="page-cart-item">
      <div class="page-cart-item-block">
        <div class="page-cart-item-block-img">
          <img src="../../assets/home/nav/zhi1.png" />
        </div>
        <div class="page-cart-item-block-title">
          <span>职业卫生</span>
        </div>
      </div>
    </div>

    <div class="page-cart-item">
      <div class="page-cart-item-block">
        <div class="page-cart-item-block-img">
          <img src="../../assets/home/nav/yi1.png" />
        </div>
        <div class="page-cart-item-block-title">
          <span>仪器检定</span>
        </div>
      </div>
    </div>

    <div class="page-cart-item">
      <div class="page-cart-item-block">
        <div class="page-cart-item-block-img">
          <img src="../../assets/home/nav/qi1.png" />
        </div>
        <div class="page-cart-item-block-title">
          <span>其他标物</span>
        </div>
      </div>
    </div>
    <div class="page-cart-item">
      <div class="page-cart-item-block">
        <div class="page-cart-item-block-img">
          <img src="../../assets/home/nav/hua1.png" />
        </div>
        <div class="page-cart-item-block-title">
          <span>化学试剂</span>
        </div>
      </div>
    </div>

    <div class="page-cart-item">
      <div class="page-cart-item-block">
        <div class="page-cart-item-block-img">
          <img src="../../assets/home/nav/yan1.png" />
        </div>
        <div class="page-cart-item-block-title">
          <span>实验室配</span>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      step: [
        {
          id: 1,
          pic: require('@/assets/home/nav/biao1.png'),
          title: '标准气体',
          isActive: false,
        },
        {
          id: 2,
          pic: require('@/assets/home/nav/shi1.png'),
          title: '食品标物',
          isActive: false,
        },
      ],
    };
  },
  mounted: function() {
    this.$nextTick(function() {
      window.addEventListener('scroll', this.onScroll);
    });
  },
  methods: {
    jump(index) {
      // 用 class="d_jump" 添加锚点
      let jump = document.querySelectorAll('.d_jump');
      let total = jump[index].offsetTop;
      let distance =
        document.documentElement.scrollTop || document.body.scrollTop;
      // 平滑滚动，时长500ms，每10ms一跳，共50跳
      let step = total / 50;
      if (total > distance) {
        smoothDown();
      } else {
        let newTotal = distance - total;
        step = newTotal / 50;
        smoothUp();
      }
      function smoothDown() {
        if (distance < total) {
          distance += step;
          document.body.scrollTop = distance;
          document.documentElement.scrollTop = distance;
          setTimeout(smoothDown, 10);
        } else {
          document.body.scrollTop = total;
          document.documentElement.scrollTop = total;
        }
      }
      function smoothUp() {
        if (distance > total) {
          distance -= step;
          document.body.scrollTop = distance;
          document.documentElement.scrollTop = distance;
          setTimeout(smoothUp, 10);
        } else {
          document.body.scrollTop = total;
          document.documentElement.scrollTop = total;
        }
      }
    },
    onScroll() {
      let scrolled =
        document.documentElement.scrollTop || document.body.scrollTop; // 586、1063分别为第二个和第三个锚点对应的距离
      if (scrolled >= 1022 && scrolled <= 1394) {
        this.step[0].isActive = true;
        this.step[0].pic = require('@/assets/home/nav/biao2.png');
        this.step[1].pic = require('@/assets/home/nav/shi1.png');
        this.step[1].isActive = false;
      } else if (scrolled > 1078) {
        this.step[1].isActive = true;
        this.step[1].pic = require('@/assets/home/nav/shi2.png');
        this.step[0].pic = require('@/assets/home/nav/biao1.png');
        this.step[0].isActive = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.active {
  color: #fc8f28;
}
.page-cart {
  z-index: 99;
  position: fixed;
  width: 82px;
  left: 0px;
  top: 10%;
  background: #fff;
  color: #676767;
  font-size: 12px;
  display: grid;
  justify-content: space-around;
  grid-template-columns: repeat(auto-fill, 80px);
  grid-gap: 2px 0px;

  &-item {
    width: 100%;
    height: 80px;
    position: relative;
    cursor: pointer;
    &:last-of-type {
      &:after {
        background: none;
      }
    }

    &:after {
      content: '';
      position: absolute;
      width: 80%;
      height: 2px;
      left: 0px;
      right: 0px;
      bottom: -2px;
      margin: auto;
      background: transparent;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-image: url('../../assets/cart/image-02.png');
    }

    &-block {
      position: absolute;
      height: 50px;
      width: 100%;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      margin: auto;

      &-img {
        width: 25px;
        height: 25px;
        margin: auto;

        &.drMsg {
          position: relative;

          > .msg {
            width: 24px;
            height: 16px;
            position: absolute;
            right: -16px;
            top: -6px;
            > text {
              fill: #fff;
            }
          }
        }

        > img {
          width: 100%;
          height: 100%;
        }
      }

      &-title {
        width: 100%;
        text-align: center;
      }
    }
  }
}
</style>
