<template>
  <img v-if="imageState" ref="image" :src="image" />
</template>

<script>
import { baseURL } from '@/utils/request';

function formatImageUrl(src) {
  var local = 'local://';
  var imageUrl = '';
  if (src.match(/^((https|http|ftp)?:\/\/)[^\s]+/)) {
    imageUrl = src;
  } else if (src.match(/(\.\.\/|\.\/)/) || src.indexOf(local) > -1) {
    var url = src;
    url = url.indexOf(local) > -1 ? url.substring(local.length) : url;
    url = url.match(/\.\.\//) ? url.replace(/\.\.\//g, '') : url;
    url = url.match(/\.\//) ? url.replace(/\.\//g, '') : url;
    url = url.match(/assets\//) ? url.replace(/assets\//g, '') : url;

    imageUrl = require('../../assets/' + url);
  } else {
    if (src.indexOf('/') == 0 || src.indexOf('\\')) {
      src = src.substring(1);
    }
    imageUrl = baseURL + src;
  }
  return imageUrl;
}

export default {
  props: {
    src: String | Number,
  },
  watch: {
    src: function(newValue, oldValue) {
      this.image = this.src;
    },
  },
  data() {
    return {
      tab: 'img',
      config: {
        defaultImage: require('../../assets/DefaultImage.png'),
      },
      defaultImage: null,
      imageState: true,
    };
  },
  computed: {
    image: {
      get() {
        this.$nextTick(() => {
          var that = this;
          this.$refs.image.onload = (e) => {
            //图片强制自适应
            //计算图片的尺寸
            //使其大小到完美居中
          };
          this.$refs.image.onerror = (a) => {
            that.image = that.config.defaultImage;
          };
        });
        return this.defaultImage;
      },
      set(value) {
        if (!value || value == this.config.defaultImage) {
          this.defaultImage = this.config.defaultImage;
          return;
        }
        var url = formatImageUrl(value);
        this.defaultImage = url;
      },
    },
  },
  created() {
    this.image = this.src;
  },
  methods: {
    reload() {
      this.imageState = false;
      this.$nextTick(() => {
        this.imageState = true;
      });
    },
  },
};
</script>
