import axios from 'axios';
import qs from 'qs';
import { isLogin } from './public';
import { setCookie, getCookie, delCookie } from './cookie';
import { Message, MessageBox, Loading } from 'element-ui';
import { getLanguage } from './locales';
//export const baseURL = 'http://localhost:5050/';
// export const baseURL = 'http://39.106.100.127/';
export const baseURL = 'https://biaowu.shop/';
// export const baseURL = 'http://47.104.15.158/';
//export const baseURL = 'http://47.104.87.8:8083/';
//export const baseURL = 'http://47.104.87.8:5050/';

const instance = axios.create({
  timeout: 60000,
  baseURL: baseURL,
});
//请求拦截器
instance.interceptors.request.use(
  function(config) {
    config.headers['Authorization'] = getCookie('Authorization');
    config.headers['language'] = getLanguage();
    var result = config.url.match(/(\/{0,1}([a-z]+){0,1}f\/)/g);
    if (!result) {
      isLogin(true);
    }
    //__startLoading();
    return config;
  },
  function(error) {
    //__endLoading();
    // Do something with response error
    return Promise.reject(error);
  }
);

//响应拦截
axios.interceptors.response.use(
  function(response) {
    // token 已过期，重定向到登录页面，清空用户信息
    if (response.data.Status == 7) {
      //store.commit("clearUserInfo");
      //router.push('/login');
    }
    //__endLoading();
    return response;
  },
  function(error) {
    // __endLoading();
    // Do something with response error
    return Promise.reject(error);
  }
);
// let loading;
// function __startLoading() {
//   loading = Loading.service({
//     lock: true, // 是否锁定
//     text: '正在加载中...', // 加载中需要显示的文字
//     background: 'rgba(0,0,0,.7)', // 背景颜色
//   });
// }
// // 结束加载动画
// function __endLoading() {
//   loading.close();
// }
//get请求
export function get(url, config) {
  return instance.get(url, config);
}
//post请求
export function post(url, data, config) {
  return instance.post(url, data, config);
}

export function getextra(url, data, config) {
  if (url.indexOf('?') == -1) {
    url += '?';
  } else {
    url += '&';
  }
  url += qs.stringify(data);
  return new Promise((resolve, reject) => {
    instance
      .get(url, config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function GetextraPromiseData(url, data, config) {
  return new Promise((resolve, reject) => {
    getextra(url, data, config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function postextra(url, data, config) {
  return new Promise((resolve, reject) => {
    post(url, data, config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function GetPromiseData(url, config) {
  return new Promise((resolve, reject) => {
    get(url, config)
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function PostPromiseData(url, data, config) {
  return new Promise((resolve, reject) => {
    post(url, data, config)
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function DraftPromiseData(data) {
  return new Promise((resolve, reject) => {
    resolve(data);
  });
}

FormData.prototype.setJson = (data) => {
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      this.append(key, data[key]);
    }
  }
  return this;
};

export function PreGet(url, data, config) {
  return new Promise((resolve, reject) => {
    if (url.indexOf('?') == -1) {
      url += '?';
    } else {
      url += '&';
    }
    url += qs.stringify(data, {
      indices: false,
    });

    return instance({
      ...config,
      method: 'get',
      url: url,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function PreGetData(url, data, config) {
  return new Promise((resolve, reject) => {
    PreGet(url, data, config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function PrePost(url, data, config) {
  return new Promise((resolve, reject) => {
    instance({
      ...config,
      method: 'post',
      url: url,
      data: data,
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function PrePostData(url, data, config) {
  return new Promise((resolve, reject) => {
    PrePost(url, data, config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function PrePostJson(url, data, config) {
  return new Promise((resolve, reject) => {
    PrePost(url, JSON.stringify(data), {
      ...config,
      headers: {
        'content-Type': 'application/json; charset=UTF-8',
      },
    })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function PrePostJsonData(url, data, config) {
  return new Promise((resolve, reject) => {
    PrePostJson(url, data, config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function PrePostFormData(url, data, config) {
  return new Promise((resolve, reject) => {
    PrePost(url, toFormData(data), {
      ...config,
      headers: {
        'content-Type': 'multipart/form-data',
      },
    })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export function toFormData(data) {
  if (data instanceof FormData) {
    return data;
  } else {
    return new FormData().setJson(data);
  }
}

export function upload(url, data, config) {
  var data = new FormData();
  data.append('file', file);
  return PrePostFormData(url, data, config);
}

export default instance;
