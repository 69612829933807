<template>
  <div>
    <Header />
    <Search msg="个人中心" />
    <div class="con w-1920">
      <div class="center w-1200">
        <Left_menu />
        <router-view />
      </div>
    </div>
    <!-- <Baoyou /> -->
    <div class="kongbai"></div>
    <Foot />
    <Cart />
  </div>
</template>
<script>
import Header from '@/components/header.vue';
import Foot from '@/components/foot.vue';
import Cart from '@/components/cart.vue';
import Left_menu from '@/components/order/left_menu.vue';
import Search from '@/components/order/search.vue';
import Baoyou from '@/components/order/baoyou.vue';
export default {
  name: 'Order',
  components: {
    Header,
    Foot,
    Left_menu,
    Search,
    Baoyou,
    Cart,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
li {
  list-style: none;
}

.con {
  width: 100%;
  height: 100%;
  background: #f5f5f5;
}
.center {
  width: 1236px;
  margin: 0px auto;
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
}
</style>
