<template>
  <div class="logo w-1920">
    <div class="logo1 w-1200">
      <div class="img" @click="home">
        <img src="../../assets/order/logo.png" alt="" />
      </div>
      <router-link class="about" :to="{ name: 'user-centered' }" tag="div">
        <!-- <router-link class="user" :to="{ name: 'user-centered' }" tag="div">
          个人中心
        </router-link>
        <router-link class="shouye" :to="{ name: 'Home' }" tag="div"
          >返回首页</router-link
        > -->
        {{ msg }}
        <!-- 个人中心 -->
      </router-link>
      <div class="menu">
        <!-- <router-link :to="{ name: 'Home' }" tag="div" class="shouye">
          <span>首页</span>
          <img src="../../assets/order/xiajiantou.png" alt="" />
        </router-link>
        <router-link :to="{ name: 'Safe' }" tag="div" class="shouye zhanghu">
          <span>账户设置</span>
          <img src="../../assets/order/xiajiantou.png" alt="" />
        </router-link> -->
        <!-- <div class="shouye xiaoxi">
          <span>消息</span>
          <img src="../../assets/order/xiajiantou.png" alt="" />
          <div class="tishi">12</div>
        </div> -->
      </div>
      <div class="search">
        <!-- <div class="center">
          <input
            type="text"
            onkeyup="this.value=this.value.replace(/[, ]/g,'')"
          />
        </div>
        <div class="right">搜索</div> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'search',
  props: {
    msg: String,
  },
  methods: {
    home() {
      this.$router.push({
        name: 'Home',
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.logo {
  width: 100%;
  height: 95px;
  background: #1873ff;
  color: #fff;
  .logo1 {
    width: 1182px;
    height: 95px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    // justify-content: space-between;
    .img {
      width: 158px;
      height: 45px;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .about {
      cursor: pointer;
      font-size: 20px;
      margin-left: 27px;
      //margin-left: -140px;
      // display: flex;
      // flex-direction: column;
      // .shouye {
      //   border: 1px solid #fff;
      //   height: 25px;
      //   width: 80px;
      //   line-height: 25px;
      //   text-align: center;
      //   border-radius: 5px;
      //   cursor: pointer;
      // }
      // .user {
      //   cursor: pointer;
      // }
    }
    .menu {
      font-size: 20px;
      display: flex;
      .shouye {
        cursor: pointer;
        img {
          width: 15px;
          height: 8px;
          margin-left: 6px;
        }
      }
      .zhanghu {
        margin-left: 29px;
      }
      .xiaoxi {
        margin-left: 29px;
        position: relative;
        .tishi {
          font-size: 12px;
          color: #fff;
          background: #fe5a50;
          text-align: center;
          border-radius: 10px;
          width: 30px;
          position: absolute;
          top: -16px;
          right: -15px;
        }
      }
    }
    .search {
      width: 458px;
      height: 30px;
      display: flex;
      display: -webkit-flex;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      .center {
        width: 362px;
        padding: 5px 5px;
        input {
          border: 0;
          outline: none;
          height: 100%;
          width: 100%;
          padding-left: 10px;
          background: #1873ff;
        }
      }
      .right {
        font-size: 18px;
        color: #1a53ff;
        background: #fff;
        height: 30px;
        text-align: center;
        width: 98px;
        line-height: 30px;
        border: none;
      }
    }
  }
}
</style>
