<template>
  <div id="app">
    <router-link to="/"></router-link>
    <router-view
      :key="$router.currentRoute.fullPath"
      v-if="routerState"
    ></router-view>
  </div>
</template>

<style lang="scss"></style>

<script>
export default {
  name: 'App',
  //向子组件注入依赖
  provide() {
    return {
      reload: this.reload,
    };
  },
  //声明变量
  data() {
    return {
      routerState: true,
    };
  },
  methods: {
    //利用$nextTick特性，router跳转完成后展示页面
    reload() {
      this.routerState = false;
      this.$nextTick(() => {
        this.routerState = true;
      });
    },
  },
};
</script>
<style>
div {
  cursor: default;
}
.kongbai {
  width: 100%;
  height: 50px;
  background: #f5f5f5;
}
</style>
