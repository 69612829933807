<template>
  <div class="left">
    <div class="menu">
      <span class="spen">订单中心</span>
      <ul class="ul">
        <router-link :to="{ name: 'Dingdan' }" tag="li">我的订单</router-link>
        <router-link :to="{ name: 'Shaidan' }" tag="li">评价晒单</router-link>
        <router-link :to="{ name: 'Changgou' }" tag="li"
          >我的常购商品</router-link
        >
      </ul>
    </div>
    <div class="menu">
      <span class="spen">资产中心</span>
      <ul class="ul">
        <router-link :to="{ name: 'Yue' }" tag="li">余额</router-link>
        <router-link :to="{ name: 'Youhui' }" tag="li">优惠券</router-link>
        <router-link :to="{ name: 'Shouxin' }" tag="li">授信额度</router-link>
        <router-link :to="{ name: 'Shopcard' }" tag="li">购物卡</router-link>
        <!--        <li>积分</li>-->
        <router-link :to="{ name: 'Jifen' }" tag="li">积分</router-link>
      </ul>
    </div>
    <div class="menu">
      <span class="spen">客户服务</span>
      <ul class="ul">
        <!-- <router-link :to="{ name: 'Tuihuo' }" tag="li">退换货</router-link> -->
        <router-link :to="{ name: 'Fapiao' }" tag="li">我的发票</router-link>
        <!--        <router-link :to="{ name: 'Jingli' }" tag="li">客户经理</router-link>-->

        <!--客户经理显示-->
        <router-link :to="{ name: 'HuiYuan' }" tag="li" v-if="isManager"
          >我的会员</router-link
        >
        <router-link :to="{ name: 'MdingDan' }" tag="li" v-if="isManager"
          >代购订单</router-link
        >
        <router-link :to="{ name: 'ShenHe' }" tag="li" v-if="isManager"
          >订单审核</router-link
        >

        <!--普通会员显示-->
        <!--        <router-link :to="{ name: 'MdingDan' }" tag="li" v-if="!isManager">我的代购订单</router-link>-->
      </ul>
    </div>
    <div class="menu">
      <span class="spen">关注中心</span>
      <ul class="ul">
        <router-link :to="{ name: 'wares' }" tag="li">关注的商品</router-link>
        <router-link :to="{ name: 'store' }" tag="li">关注的店铺</router-link>
        <!-- <router-link :to="{ name: 'route' }" tag="li">我的足迹</router-link> -->
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </div>
    <div class="menu">
      <span class="spen">账户设置</span>
      <ul class="ul">
        <router-link :to="{ name: 'User' }" tag="li">个人信息</router-link>
        <router-link :to="{ name: 'Safe' }" tag="li">账户安全</router-link>
        <router-link :to="{ name: 'Bind' }" tag="li">账号绑定</router-link>
        <router-link :to="{ name: 'Address' }" tag="li">收货地址</router-link>
      </ul>
    </div>
  </div>
</template>
<script>
import { getCookie } from '@/utils/cookie';

export default {
  data() {
    return {
      isManager: false,
    };
  },
  created() {
    const managerType = getCookie('managerType');
    if (managerType == '') {
      this.isManager = false;
    } else if (managerType == 'null') {
      this.isManager = false;
    } else if (managerType == '0') {
      this.isManager = false;
    } else {
      this.isManager = true;
    }
  },
};
</script>
<style lang="scss" scoped>
li {
  list-style: none;
}
.left {
  width: 155px;
  background: #fff;
  .menu {
    padding: 36px 0;
    padding-left: 30px;
    border-bottom: 1px solid #ededed;
    .spen {
      font-size: 18px;
      color: #333;
      font-weight: 600;
    }
    .ul {
      font-size: 15px;
      color: #333;
      padding: 0;
      li {
        margin-top: 10px;
        list-style: none;
        cursor: pointer;
        &.router-link-exact-active.router-link-active {
          color: #3661fe;
        }
      }
    }
    .bian {
      color: #1a52ff;
    }
  }
}
</style>
