import router from '@/router/index';
import { setCookie, getCookie, delCookie } from '@/utils/cookie';

const isLogin = function (isRouter) {
  let token = getCookie('Authorization');
  if (!token) {
    isRouter &&
      router.tp({
        name: 'Login',
      });
    isRouter &&
      console.warn(
        "If you don't log in, you will be forced to leave the page and log in"
      );

    return false;
  }
  return true;
}


const getObj = function (key){
  let item = localStorage.getItem(key);
  return item?JSON.parse(item):null
}

const setObj = function (key,obj){
  let value = JSON.stringify(obj)
  localStorage.setItem(key,value)
}

const delObj = function (key){
  localStorage.removeItem(key);
}

export {isLogin,getObj,setObj,delObj}
