import VueI18n from 'vue-i18n'
import Vue from 'vue'
import en from '@/locales/en'
import zh from '@/locales/zh'
import { Message } from 'element-ui'

Vue.use(VueI18n)
// 是否允许页面切换语言
const allowDisplay = false
// 是否跟随浏览器
const followSystem = false
// 是否允许在当前语言和将要切换语言相同时切换（当相与刷新页面）
const allowSameSwitch = false
// 默认语言
const defaultLang = 'zh'
// 获取浏览器语言标识
const rawLang = (navigator.language || navigator.userLanguage).toLowerCase()
// 缓存中的语言表示（可以替换成和用户关联的）
const savedLang = localStorage.getItem('locale')
// 主语言代码
const mainLang = savedLang || rawLang.substring(0, rawLang.indexOf('-'))
// 匹配支持的语言列表
const supportedLangs = ['en', 'zh']
// 当前系统语言
const systemLang = supportedLangs.includes(mainLang) ? mainLang : defaultLang

const locales = new VueI18n({
  locale: followSystem ? systemLang : savedLang || defaultLang, // 默认语言
  fallbackLocale: defaultLang, // 回退语言,
  // 自定义数字格式
  numberFormats: {
    en: {
      currency: {
        style: 'currency',
        currency: 'USD'
      },
      currency2: {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
      },
      toFixed2: {
        style: 'decimal',
        minimumFractionDigits: 2
      }
    },
    zh: {
      currency: {
        style: 'currency',
        currency: 'CNY'
      },
      currency2: {
        style: 'currency',
        currency: 'CNY',
        minimumFractionDigits: 2
      },
      toFixed2: {
        style: 'decimal',
        minimumFractionDigits: 2
      }
    }
  },
  messages: {
    en,
    zh
  }
})

export function change(lang) {
  if (!supportedLangs.includes(lang)) {
    console.error(locales.t(`locales.error`, { targetLanguage: lang }))
    Message['error']({
      message: locales.t(`locales.error`, { targetLanguage: lang })
    })
    if (getLanguage() !== defaultLang) {
      Message['info']({
        message: locales.t(`locales.error`, { secLanguage: 3, defaultLang })
      })
      setTimeout(() => {
        change(defaultLang)
      }, 2400)
    }
    return
  }

  if (!allowSameSwitch && getLanguage() == lang) {
    return
  }
  localStorage.setItem('locale', lang)
  locales.locale = lang
  setTimeout(() => {
    location.reload()
  }, 600)
}

export function getLanguage() {
  return localStorage.getItem('locale') || locales.locale
}

Vue.component('locales', {
  props: {
    followText: {
      type: Boolean,
      default: false
    }
  },
  render: function (h) {
    const { followText } = this

    let langText = locales.t('locales.lang')
    if (followText) {
      langText = locales.t(`locales.${getLanguage()}`)
    }
    if (!allowDisplay) {
      return h(null, {}, '不允许切换多语言')
    }
    return h('div', {}, [
      h('el-dropdown', { props: { trigger: 'click' } }, [
        h('span', { class: 'el-dropdown-link' }, [langText, h('i', { class: 'el-icon-arrow-down el-icon--right' })]),
        h(
          'el-dropdown-menu',
          { class: 'dropdown-locales', slot: 'dropdown' },
          supportedLangs.map((lang) => {
            return h('el-dropdown-item', {}, [
              h('a', { style: 'display: inline-block;width: 100%;height: 100%;', href: 'javascript:void(0)', on: { click: () => change(lang) } }, locales.t(`locales.${lang}`))
            ])
          })
        )
      ])
    ])
  }
})

export default locales
